import React, { useState, useEffect } from 'react';
import axios from 'axios';

function Message() {
    const [message, setMessage] = useState('Loading...');
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchMessage = async () => {
            try {
                const response = await axios.get('http://192.210.139.24:3000/api/hello', {
                    timeout: 5000,
                    headers: {
                        'Accept': 'application/json',
                        'Access-Control-Allow-Origin': '*'
                    }
                });
                setMessage(response.data.message);
            } catch (error) {
                console.error('Error details:', error);
                if (error.code === 'ECONNREFUSED') {
                    setError('Unable to connect to the server');
                } else if (error.code === 'ETIMEDOUT') {
                    setError('Request timed out');
                } else {
                    setError(`Failed to load message: ${error.message}`);
                }
            }
        };

        fetchMessage();
    }, []);

    if (error) {
        return (
            <div className="error">
                <p>{error}</p>
                <p>Please check if the server is running and accessible.</p>
            </div>
        );
    }

    return <div className="message">{message}</div>;
}

export default Message; 